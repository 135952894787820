import { getData, postData, putData, deleteData } from "./fetchhandler.js";

window.services = window.services || {};
window.services.shoppingcart = window.services.shoppingcart || {};
window.services.shoppingcart = (function () {
  let config = {},
    get = async function () {
      let result = await getData(`/api/shoppingcart`).then(
        (data) => {
          return data;
        }
      );

      return result;
    },
    add = async function (dataItem) {
      let result = await postData(
        `/api/shoppingcart/add`,
        dataItem,
        false
      ).then((data) => {
        return data;
      });

      return result;
    },
    update = async function (id, dataItem) {
      let result = await postData(
        `/api/shoppingcart/update/${id}`,
        dataItem,
        false
      ).then((data) => {
        return data;
      });

      return result;
    },
    remove = async function (id) {
      let result = await deleteData(
        `/api/shoppingcart/delete`,
        id,
        false
      ).then((data) => {
        return data;
      });

      return result;
    },
    clear = async function () {
      let result = await postData(
        `/api/shoppingcart/clear`,
        false
      ).then((data) => {
        return data;
      });

      return result;
    };
  return {
    get: get,
    add: add,
    update: update,
    remove: remove,
    clear: clear
  };
})();
